import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import DataTableController from "../../components/database/DataTableController/DataTableController";
import { CertificateSchema, CourseSchema, SkillSchema, SkillsCategorySchema } from "../../utils/schemas";

export default function Risorse() {
    return (
        <div className="content">
            <PageLabelBar title="Gestione Risorse" />
            <DataTableController limitedTables={[CertificateSchema, CourseSchema, SkillSchema, SkillsCategorySchema]}
                editAction deleteAction />
        </div>
    );
}