import { useAuth } from "oidc-react";
import DataTable from "../../components/common/DataTable/DataTable";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import ApiProvider from "../../utils/ApiProvider";
import UserDataUtils from "../../utils/UserDataUtils";
import { ProposalSchema } from "../../utils/schemas";
import { Link, useLocation } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { AccessTime, Approval, Check, Close, People } from "@mui/icons-material";
import { useState } from "react";
import ApprovalModal, { ApprovalModalState, ApproveState, RejectState, UnderApprovalState } from "../../components/proposte/ApprovalModal";

type ProposteModalState = {
    internalState: ApprovalModalState,
    row: any
}

export default function Proposte() {
    const [ dataTableKey, setDataTableKey ] = useState(new Date().toString());
    const [ approvalModalOpen, setApprovalModalOpen ] = useState(false);
    const [ approvalModalState, setApprovalModalState ] = useState<ProposteModalState>({
        internalState: UnderApprovalState,
        row: {}
    });
    const auth = useAuth();
    const location = useLocation();

    const api = ApiProvider.getApi();
    
    let externalUserId: string | undefined;
    let externalUserName: string | undefined;
    let openAddModal = false;
    if (location.state) {
        if (location.state.userId) {
            externalUserId = location.state.userId;
        }

        if (location.state.name) {
            externalUserName = location.state.name;
        }

        if (location.state.openAddModal) {
            openAddModal = location.state.openAddModal
        }
    }

    function reloadDataTableKey() {
        setDataTableKey(new Date().toString());
    }

    function openApprovalModal() {
        setApprovalModalOpen(true);
    }

    function closeApprovalModal() {
        setApprovalModalOpen(false);
    }

    function openModal(state: ApprovalModalState, row: any) {
        setApprovalModalState({ internalState: state, row });
        openApprovalModal();
    }

    const getTeamMemberActions = (params: GridRowParams<any>) => {
        return [
            <GridActionsCellItem icon={<Approval />} label="Manda in Approvazione" title="Manda in Approvazione"
                onClick={() => openModal(UnderApprovalState, params.row)} disabled={!(params.row.state === "creation")} />
        ];
    }

    const getManagerActions = (params: GridRowParams<any>) => {
        return [
            <GridActionsCellItem icon={<Check />} label="Approva" title="Approva"
                onClick={() => openModal(ApproveState, params.row)} color="success" disabled={!(params.row.state === "under_approval")} />,
            <GridActionsCellItem icon={<Close />} label="Rifiuta" title="Rifiuta"
                onClick={() => openModal(RejectState, params.row)} color="error" disabled={!(params.row.state === "under_approval")} />
        ];
    }

    const teamMemberEditActionPredicate = (row: any) => {
        return row.state === "creation";
    }

    const managerEditActionPredicate = (row: any) => {
        return row.state === "creation" || row.state === "under_approval";
    }

    const deleteActionPredicate = (row: any) => {
        return row.state === "creation";
    }

    // If an External User ID is present, a manager is viewing their member's proposals.
    function isManagerViewing() {
        return externalUserId !== undefined;
    }

    // Disable editing Course fields if the Course is not temporary (this is also checked api-side).
    function getEditDisabledFields(params: any): string[] {
        if (params.course.temporary === false) {
            return ["name", "duration", "url", "description", "in_presence", "city", "postal_code", "street", "house_number"];
        }
        return [];
    }

    return (
        <>
            <div className="content">
                <PageLabelBar title={`Le ${externalUserName ? `proposte di ${externalUserName}` : "tue Proposte"}`} />
                <div className="fullWidth">
                    { isManagerViewing() && auth.userData &&
                        <DataTable key={`${dataTableKey}-internal`} api={api} table={ProposalSchema.name} addItemButton exportDataButton
                            userId={externalUserId} getActions={getManagerActions} editAction deleteAction
                            editActionPredicate={managerEditActionPredicate}
                            deleteActionPredicate={deleteActionPredicate}
                            getEditDisabledFields={getEditDisabledFields}></DataTable>
                    }
                    { !isManagerViewing() && auth.userData &&
                        <DataTable key={`${dataTableKey}-external`} api={api} table={ProposalSchema.name} addItemButton exportDataButton
                            userId={UserDataUtils.getUserId(auth.userData)} editAction deleteAction
                            editActionPredicate={teamMemberEditActionPredicate}
                            getActions={getTeamMemberActions}
                            deleteActionPredicate={deleteActionPredicate}
                            getEditDisabledFields={getEditDisabledFields}
                            openAddModal={openAddModal}></DataTable>
                    }
                </div>
                { externalUserId &&
                    <div className="belowDataTableButtonBar fullWidth">
                        <Stack gap={"8px"} width={"fit-content"}>
                            <Link to={"/team"} state={{}}><Button variant="contained" startIcon={<People />} sx={{ justifyContent: "left" }} fullWidth><Typography>Torna al tuo Team</Typography></Button></Link>
                            <Link to={"/proposte"} state={{}}><Button variant="contained" startIcon={<AccessTime />} sx={{ justifyContent: "left" }} fullWidth><Typography>Vai alle tue Proposte</Typography></Button></Link>
                        </Stack>
                    </div>
                }
            </div>
            <ApprovalModal api={api} auth={auth} onSuccessFunction={reloadDataTableKey} parentCloseFunction={closeApprovalModal}
                parentOpenFunction={openApprovalModal} open={approvalModalOpen} row={approvalModalState.row}
                state={approvalModalState.internalState} />
        </>
    );
}