import "./DataTable.scss";
import { DataGrid, GridActionsCellItem, GridActionsCellItemProps, GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridFilterInputValue, GridFilterItem, GridFilterModel, GridFilterOperator, GridPaginationModel, GridRowParams, GridRowSelectionModel, GridSortItem, GridSortModel, getGridDateOperators, getGridNumericOperators, getGridStringOperators, useGridApiRef } from "@mui/x-data-grid";
import { Button, Checkbox, Chip, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import { Add, Delete, Download, Edit, Notes, Refresh, Upload } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import ApiAdapter from "../../../api/ApiAdapter";
import { useAuth } from "oidc-react";
import { SchemaDefinition } from "../../../utils/schemas";
import SchemaUtils from "../../../utils/SchemaUtils";
import AddItemModal, { AddModalMode, EditModalMode, ModalMode } from "../AddItemModal/AddItemModal";
import DeleteItemModal from "../DeleteItemModal/DeleteItemModal";
import TextDisplayModal from "../TextDisplayModal/TextDisplayModal";
import LocationUtils from "../../../utils/LocationUtils";
import { useLocation } from "react-router-dom";
import { SnackNotificationContext } from "../../../app/App";
import EnumSelectFilter from "../EnumSelectFilter/EnumSelectFilter";
import { SelectOption } from "../../../utils/types";

type TableState = {
    paginationModel: GridPaginationModel,
    filterModel: GridFilterModel,
    sortModel: GridSortModel,
    rowSelectionModel: GridRowSelectionModel,
    columnVisibilityModel: GridColumnVisibilityModel
};

type TextDisplayModalState = {
    title: string,
    text: string
};

type QuickFilterConfig = {
    field: string,
    operator: string,
    value: string,
    label: string
}

/**
 * @param table - the API name of the table to display data from (eg. ProposalSchema.name)
 * @param api - object implementing the `ApiAdapter` interface to use for API calls
 * @param addItemButton - enable to show a button to add items to the table
 * @param exportDataButton - enable to show a button to download the selected data rows
 * @param importDataButton - enable to show a button for mass data importing (currently unsupported)
 * @param editAction - enable to show the Edit action beside every row
 * @param deleteAction - enable to show the Delete action beside every row
 * @param editActionPredicate - predicate to decide whether the Edit action should be enabled for each given row
 * @param deleteActionPredicate - predicate to decide whether the Delete action should be enabled for each given row
 * @param additionalButtons - a Node containing other items to be rendered in the button bar, above the table
 * @param userId - makes the table user-centric, making API calls related to the given user ID
 * @param getActions - a function that returns a list of Action elements to be added beside each row
 * @param getEditDisabledFields - a function that returns a list of field names that should be disabled while editing the given row
 * @param overrideGetRowCount - a function that should be called instead of the defaults, to get the total number of rows
 * @param overrideGetData - a function that should be called instead of the defaults, to get the data rows to display
 * @returns 
 */
export default function DataTable({
    table,
    api,
    addItemButton = false,
    addItemButtonTitle,
    exportDataButton = false,
    importDataButton = false,
    quickFilterConfig,
    editAction = false,
    deleteAction = false,
    editActionPredicate,
    deleteActionPredicate,
    additionalButtons,
    userId,
    getActions,
    getEditDisabledFields,
    overrideGetRowCount,
    overrideGetData,
    openAddModal
}: {
    table: string,
    api: ApiAdapter,
    addItemButton?: boolean,
    addItemButtonTitle?: string,
    exportDataButton?: boolean,
    importDataButton?: boolean,
    quickFilterConfig?: QuickFilterConfig,
    editAction?: boolean,
    deleteAction?: boolean,
    editActionPredicate?: (row: any) => boolean,
    deleteActionPredicate?: (row: any) => boolean,
    additionalButtons?: React.ReactNode,
    userId?: string,
    getActions?: (params: GridRowParams<any>) => React.ReactElement<GridActionsCellItemProps, string | React.JSXElementConstructor<any>>[],
    getEditDisabledFields?: (params: any) => string[],
    overrideGetRowCount?: (tableName: string, token: string) => Promise<number>,
    overrideGetData?: (tableName: string, token: string, pageSize: number, pageOffset: number,
        filters: GridFilterItem[], sort: GridSortItem[]) => Promise<Array<{[key: string]: any}>>,
    openAddModal?: boolean
}) {
    // Table Information and State
    const [ schema, setSchema ] = useState<SchemaDefinition>({ name: "loading", plural: "loading", display: "loading", displaySingular: "loading", colDef: [], hiddenColNames: [] });
    const [ rows, setRows ] = useState([] as Array<{[key: string]: any}>);
    const [ rowCount, setRowCount ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const [ errorOccurred, setErrorOccurred ] = useState(false);
    const [ tableState, setTableState ] = useState<TableState>({
        paginationModel: { page: 0, pageSize: 10 },
        filterModel: { items: [] },
        sortModel: [],
        rowSelectionModel: [],
        columnVisibilityModel: {}
    });
    // Quick Filter
    const [ quickFilterChecked, setQuickFilterChecked ] = useState(false);
    // Add Item Modal
    const [ addItemModalOpen, setAddItemModalOpen ] = useState(openAddModal ?? false);
    const [ addItemModalDefaultValues, setAddItemModalDefaultValues ] = useState<{[key: string]: any} | undefined>(undefined);
    const [ addItemModalMode, setAddItemModalMode ] = useState<ModalMode>(AddModalMode);
    // Delete Item Modal
    const [ deleteItemModalOpen, setDeleteItemModalOpen ] = useState(false);
    const [ deleteItemModalRow, setDeleteItemModalRow ] = useState<any>({})
    // Modal for multiline text display
    const [ textDisplayModalOpen, setTextDisplayModalOpen ] = useState(false);
    const [ textDisplayModalState, setTextDisplayModalState ] = useState<TextDisplayModalState>({ title: "", text: "" });
    const snackNotificationContext = useContext(SnackNotificationContext);
    const auth = useAuth();
    const location = useLocation();
    const dataGridApiRef = useGridApiRef();
    // Operators to remove from sorting / filtering options
    const unsupportedStringOperators = [ "isAnyOf" ];
    const unsupportedNumericOperators = [ "isAnyOf" ];
    const unsupportedDateOperators = [ "is", "not" ]

    // Load data everytime the loading state  is set to true, or an error occurred with a request.
    useEffect(() => {
        if (auth.userData && (loading || errorOccurred)) {
            setErrorOccurred(false);
            populateTable();
            dataGridApiRef.current.autosizeColumns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, loading, errorOccurred]);

    function refreshTable() {
        setLoading(true);
    }

    /**
     * @returns a dummy function to pass as applyFiltersFn, which is never called in server-side mode
     */
    function getDummyApplyFilterFunction() {
        return (filterItem: any, column: any) => {
            return (value: any, row: any, column: any, apiRef: any) => {
                return true;
            };
        }
    }

    /**
     * Returns a Grid Filter Operator that allows the user to select a specific value of an enum field.
     * @param possibilities the possible values of the enum field.
     * @returns the operator definition object to feed into the filterOperators property of a column.
     */
    function generateEnumSelectOperatorWithPossibilities(possibilities: SelectOption[]): GridFilterOperator<any, string> {
        return {
            label: 'equals',
            value: 'equals',
            // Not called in server-side mode
            getApplyFilterFn: getDummyApplyFilterFunction(),
            InputComponent: EnumSelectFilter,
            InputComponentProps: {
                possibilities
            }
        };
    }

    /**
     * Modifies the column definitions to remove unsupported operators.
     * @param schemaCols initial definition of the table's columns from schemas.ts
     * @returns the modified definition, without the unsupported operators defined.
     */
    function removeUnsupportedOperators(schemaCols: GridColDef[]): GridColDef[] {
        return schemaCols.map(col => {
            if (col.type === "string") {
                return {
                    ...col,
                    filterOperators: getGridStringOperators().filter(op => !unsupportedStringOperators.includes(op.value))
                }
            }

            if (col.type === "number") {
                return {
                    ...col,
                    filterOperators: getGridNumericOperators().filter(op => !unsupportedNumericOperators.includes(op.value))
                }
            }

            if (col.type === "date") {
                return  {
                    ...col,
                    filterOperators: getGridDateOperators().filter(op => !unsupportedDateOperators.includes(op.value))
                }
            }

            return col;
        });
    }

    function addStringContainsIgnoreCaseOperator(schemaCols: GridColDef[]): GridColDef[] {
        return schemaCols.map(col => {
            if (col.type === "string") {
                return {
                    ...col,
                    filterOperators: [{ getApplyFilterFn: getDummyApplyFilterFunction(), label: "contains (case insensitive)", value: "contains_case_insensitive", InputComponent: GridFilterInputValue }, ...(col.filterOperators ? col.filterOperators : [])]
                }
            }

            return col;
        })
    }

    function addEnumFilterOperator(schemaCols: GridColDef[]): GridColDef[] {
        return schemaCols.map(col => {
            if (col.type !== "string") {
                return col;
            }

            const possibilities = SchemaUtils.getEnumPossibilities(schema, col.field);
            if (possibilities.length > 0) {
                return {
                    ...col,
                    filterOperators: [generateEnumSelectOperatorWithPossibilities(possibilities)]
                }
            }

            return col;
        });
    }

    /**
     * Adds a dummy "id" column, if one such column is not already present.
     * The id column is required for the Data Grid to work.
     * @param tableRows the list of data rows to check, and possibly add an id to
     */
    function checkIdFieldAndSetRows(tableRows: {[key: string]: any}[]) {
        const finalTableRows = tableRows.map((row, index) => {
            if (row.id) {
                return row;
            }
            return { ...row, "id": index };
        });
        setRows(finalTableRows);
    }

    /**
     * Hides certain columns from the user's view, based on the `hiddenColNames` property in the schema definition.
     * Also hides ID columns, in pages other than the Database page.
     * @param newSchema the schema definition to read the `hiddenColNames` property from
     */
    function updateColumnVisiblityModel(newSchema: SchemaDefinition) {
        let columnVisibilityObject = Object.fromEntries(newSchema.hiddenColNames.map(colName => {
            // On the database page, IDs might be useful to see.
            // Everywhere else, hide those along with the other specified fields.
            if (colName.match(/id$/) && LocationUtils.isDatabasePage(location.pathname)) {
                return [colName, true]
            }
            return [colName, false];
        }));
        setTableState(oldState => {
            return {
                ...oldState,
                columnVisibilityModel: columnVisibilityObject
            }
        })
    }

    /**
     * Catch-all method to reset the table to the default state if an error occurs with an API request.
     * For example, catch a filter error and reset to the default (no filters) state.
     * @param error the error caught
     */
    function catchApiErrorAndResetTable(error: any) {
        console.error(error);
        setTableState({
            paginationModel: { page: 0, pageSize: 10 },
            filterModel: { items: [] },
            sortModel: [],
            rowSelectionModel: [],
            columnVisibilityModel: {}
        });
        setErrorOccurred(true);
        setLoading(true);
        snackNotificationContext.displaySnackbarMessage("error", "C'è stato un errore con la tua richiesta.");
    }

    /**
     * Returns the total number of rows that can be displayed, based on things such as filters. Useful for pagination.
     * Calls the `overrideGetRowCount` method, instead of the default ones, if specified. This allows control from the outside.
     * @param tableName API name of the table (eg. ProposalSchema.name)
     * @param token access token of the logged user
     * @returns the total number of rows that can be displayed
     */
    async function getRowCount(tableName: string, token: string): Promise<number> {
        if (overrideGetRowCount) {
            return await overrideGetRowCount(tableName, token);
        }

        if (userId) {
            return await api.getRowCountByUserId(userId, tableName, token);
        }
        return await api.getRowCount(tableName, token);
    }

    /**
     * Returns data rows based on pagination, filters, and sort.
     * Calls the `overrideGetData` method, instead of the default ones, if specified. This allows control from the outside.
     * @param tableName API name of the table (eg. ProposalSchema.name)
     * @param token access token of the logged user
     * @param pageSize number of data rows in each page
     * @param pageOffset page number to request
     * @param filters array of filter options
     * @param sort array of sort options
     * @returns data rows matching the given criteria
     */
    async function getData(tableName: string, token: string, pageSize: number, pageOffset: number,
        filters: GridFilterItem[], sort: GridSortItem[]): Promise<Array<{[key: string]: any}>> {

        if (overrideGetData) {
            return await overrideGetData(tableName, token, pageSize, pageOffset, filters, sort);
        }

        if (userId) {
            return await api.getDataByUserId(userId, tableName, token, pageSize, pageOffset, filters, sort);
        }
        return await api.getData(tableName, token, pageSize, pageOffset, filters, sort);
    }

    /**
     * Modifies the rendering of columns with multi-line text to instead display an action button,
     * which opens a modal with the multiline text.
     * A cell contains multi-line text if its inputMode is set to "text" in the schema's additionalInfo.
     * @param schema the schema definition to read the column configuration from
     */
    function addIconRenderToTextareaFields(schema: SchemaDefinition) {
        let textAreaFieldNames = [] as string[];
        if (schema.additionalInfo) {
            const additionalInfo = schema.additionalInfo;
            Object.keys(schema.additionalInfo).forEach(fieldName => {
                if (additionalInfo[fieldName].inputMode === "text") {
                    textAreaFieldNames.push(fieldName);
                }
            })
        }

        if (textAreaFieldNames.length === 0) {
            return;
        }

        schema.colDef.forEach(def => {
            if (!textAreaFieldNames.includes(def.field)) {
                return;
            }

            def.renderCell = (params) => {
                let value = dataGridApiRef.current.getRowFormattedValue(params.row, def);
                if (value) {
                    return <IconButton onClick={() => displayMultilineText(def.headerName ?? "", value)} title={`Visualizza ${def.headerName ?? ""}`}><Notes /></IconButton>
                }
            };
        });

        if (schema.userColDef) {
            schema.userColDef.forEach(def => {
                if (!textAreaFieldNames.includes(def.field)) {
                    return;
                }
    
                def.renderCell = (params) => {
                    let value = dataGridApiRef.current.getRowFormattedValue(params.row, def);
                    if (value) {
                        return <IconButton onClick={() => displayMultilineText(def.headerName ?? "", value)} title={`Visualizza ${def.headerName ?? ""}`}><Notes /></IconButton>
                    }
                };
            })
        }
    }

    /**
     * Modifies the rendering of columns corresponding to Enums, to use Chips.
     * The color of the Chips can be configured in the `possibilities` property of the schema definition's `additionalInfo` property.
     * @param schema the schema definition to read the column configuration from
     */
    function prettifyEnumFields(schema: SchemaDefinition) {
        let enumFieldNames = [] as string[];
        if (schema.additionalInfo) {
            const additionalInfo = schema.additionalInfo;
            Object.keys(schema.additionalInfo).forEach(fieldName => {
                if (additionalInfo[fieldName].possibilities) {
                    enumFieldNames.push(fieldName);
                }
            })
        }

        if (enumFieldNames.length === 0) {
            return;
        }

        schema.colDef.forEach(def => {
            if (!enumFieldNames.includes(def.field)) {
                return;
            }

            def.renderCell = (params) => {
                let value = dataGridApiRef.current.getRowFormattedValue(params.row, def);
                let possibilityObject = schema.additionalInfo![def.field].possibilities!.find(info => info.value === value);
                if (possibilityObject) {
                    return <Chip label={possibilityObject.name} color={possibilityObject.color} />
                }
            };
        });

        if (schema.userColDef) {
            schema.userColDef.forEach(def => {
                if (!enumFieldNames.includes(def.field)) {
                    return;
                }
    
                def.renderCell = (params) => {
                    let value = dataGridApiRef.current.getRowFormattedValue(params.row, def);
                    let possibilityObject = schema.additionalInfo![def.field].possibilities!.find(info => info.value === value);
                    if (possibilityObject) {
                        return <Chip label={possibilityObject.name} color={possibilityObject.color} />
                    }
                };
            })
        }
    }

    /**
     * Decides whether the Edit action should be disabled for a given row.
     * Editing is always disabled if an `editActionPredicate` is not passed from outside.
     * @param row the given row
     */
    function isEditActionDisabled(row: any): boolean {
        if (!editActionPredicate) {
            return false;
        }

        return !editActionPredicate(row);
    }

    /**
     * Decides whether the Delete action should be disabled for a given row.
     * Deleting is always disabled if an `deleteActionPredicate` is not passed from outside.
     * @param row the given row
     */
    function isDeleteActionDisabled(row: any): boolean {
        if (!deleteActionPredicate) {
            return false;
        }

        return !deleteActionPredicate(row);
    }

    /**
     * Calls the API to obtain data rows based on the table's state.
     * Composes a list of user Actions that will be able to be performed on each data row, based on external configuration.
     */
    async function populateTable() {
        if (!auth.userData) {
            return;
        }

        try {
            if (tableState.filterModel.items.length === 0) {
                const totalRowCount = await getRowCount(table, auth.userData.access_token);
                setRowCount(totalRowCount);
            }
    
            const tableSchema = await SchemaUtils.getSchema(table);
            const tableRows = await getData(table, auth.userData.access_token, tableState.paginationModel.pageSize, tableState.paginationModel.page, tableState.filterModel.items, tableState.sortModel);
            if (tableState.filterModel.items.length > 0) {
                setRowCount(tableRows.length);
            }

            // Apply injected actions.
            const finalTableSchema = { ...tableSchema } as SchemaDefinition;
            let internalGetActions = (params: GridRowParams<any>) => {
                let finalArray = [] as JSX.Element[];
                if (getActions) {
                    getActions(params).forEach(item => finalArray.push(item));
                }
    
                if (editAction) {
                    finalArray.push(
                        <GridActionsCellItem icon={<Edit />} label="Modifica" title="Modifica"
                            onClick={() => editRow(params.row)} disabled={isEditActionDisabled(params.row)} />,
                    )
                }
        
                if (deleteAction) {
                    finalArray.push(
                        <GridActionsCellItem icon={<Delete />} label="Elimina" title="Elimina"
                            onClick={() => deleteRow(params.row)} disabled={isDeleteActionDisabled(params.row)} />
                    )
                }
    
                // Group icons into a hamburger menu if there are more than 3
                if (finalArray.length > 3) {
                    let finalArrayIconsInMenu = [] as JSX.Element[];
                    finalArray.forEach(item => finalArrayIconsInMenu.push(<GridActionsCellItem {...item.props} title="" showInMenu></GridActionsCellItem>));
                    finalArray = finalArrayIconsInMenu;
                }
    
                return finalArray;
            }
    
            if (getActions || editAction || deleteAction) {
                if (finalTableSchema.userColDef && !finalTableSchema.userColDef.find(col => col.field === "actions")) {
                    // Avoid affecting the original data
                    finalTableSchema.userColDef = [ ...finalTableSchema.userColDef ];
                    finalTableSchema.userColDef.push({
                        "field": "actions",
                        "type": "actions",
                        "minWidth": 120,
                        getActions: internalGetActions
                    });
                }
                if (!finalTableSchema.colDef.find(col => col.field === "actions")) {
                    // Avoid affecting the original data
                    finalTableSchema.colDef = [ ...tableSchema.colDef ];
                    finalTableSchema.colDef.push({
                        "field": "actions",
                        "type": "actions",
                        "minWidth": 120,
                        getActions: internalGetActions
                    });
                }
                
                if (finalTableSchema.additionalInfo) {
                    finalTableSchema.additionalInfo.actions = { "automatic": true }
                } else {
                    finalTableSchema.additionalInfo = {
                        actions: { "automatic": true }
                    }
                }
            }
            // Field rendering modifications
            addIconRenderToTextareaFields(finalTableSchema);
            prettifyEnumFields(finalTableSchema);
            // Update data table states
            setSchema(finalTableSchema);
            checkIdFieldAndSetRows(tableRows);
            updateColumnVisiblityModel(finalTableSchema);
            
            setLoading(false);
        } catch (e) {
            catchApiErrorAndResetTable(e);
            return;
        }
    }

    /**
     * Activates the `TextDisplayModal` with the given title and text.
     * Usually called when pressing the button for multiline text fields.
     * @param title title of the modal
     * @param text multiline text to display in the modal
     */
    function displayMultilineText(title: string, text: string) {
        setTextDisplayModalState({ title, text });
        setTextDisplayModalOpen(true);
    }

    /**
     * Applies a css class to each header column, in order to be able to style it from the scss file.
     * @param schemaCols the list of column definitions
     * @returns the modified list of column definitions, with the added class
     */
    function applyHeaderStyles(schemaCols: GridColDef[]): GridColDef[] {
        return schemaCols.map(col => {
            return {
                ...col,
                headerClassName: "columnHeader"
            }
        });
    }

    /**
     * Returns the schema's `userColDef` if the table is user-centric and it is defined.
     * Returns the schema's `colDef` otherwise.
     */
    function getTableColDef(): GridColDef[] {
        if (userId && schema.userColDef) {
            return schema.userColDef
        }
        return schema.colDef;
    }

    /**
     * Applies modifications to the table column definitions.
     * See called methods' documentation for more info.
     * @returns the modified column definitions
     */
    function getFormattedColumnDefinition(): GridColDef[] {
        let baseDef = getTableColDef();
        baseDef = removeUnsupportedOperators(baseDef);
        baseDef = addStringContainsIgnoreCaseOperator(baseDef);
        baseDef = addEnumFilterOperator(baseDef);
        baseDef = applyHeaderStyles(baseDef);
        return baseDef;
    }

    /**
     * Event handler for pagination-related changes.
     */
    async function handlePageChange(model: GridPaginationModel, details: GridCallbackDetails<any>) {
        if (!auth.userData) {
            return;
        }

        let newPageSize = model.pageSize;
        let newPage = model.page;

        setTableState(oldState => {
            return { ...oldState, paginationModel: { page: newPage, pageSize: newPageSize } }
        });
            
        setLoading(true);
    }

    /**
     * Event handler for filter-related changes.
     */
    async function handleFilterChange(model: GridFilterModel, details: GridCallbackDetails<"filter">) {
        if (!auth.userData) {
            return;
        }

        // If there is a quick filter checkbox, update its checked state as needed
        if (quickFilterConfig) {
            if (model.items.length === 0) {
                setQuickFilterChecked(false);
            } else {
                const filter = model.items[0];
                if (filter.field !== quickFilterConfig.field) {
                    setQuickFilterChecked(false);
                } else if (filter.field === quickFilterConfig.field && filter.operator === quickFilterConfig.operator && filter.value === quickFilterConfig.value) {
                    setQuickFilterChecked(true);
                }
            }
        }

        setTableState(oldState => {
            return { ...oldState, filterModel: model }
        });
            
        setLoading(true);
    }

    /**
     * Event handler for the quick-filter checkbox.
     */
    async function handleQuickFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        if (checked) {
            handleFilterChange({ items: [{ field: quickFilterConfig!.field, operator: quickFilterConfig!.operator, value: quickFilterConfig!.value }] }, {});
        } else {
            // Works because base version can only have one filter.
            // Otherwise, would have to specifically search for and delete the Quick Filter entry.
            if (tableState.filterModel.items[0].field === quickFilterConfig!.field) {
                handleFilterChange({ items: [] }, {});
            }
        }

        setQuickFilterChecked(checked);
    }

    /**
     * Event handler for sort-related changes.
     */
    async function handleSortChange(model: GridSortModel, details: GridCallbackDetails<any>) {
        if (!auth.userData) {
            return;
        }

        setTableState(oldState => {
            return { ...oldState, sortModel: model }
        });

        setLoading(true);
    }

    /**
     * Event handler for the user selecting rows for operations.
     */
    function handleRowSelection(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) {
        setTableState(oldState => {
            return { ...oldState, rowSelectionModel }
        });
    }

    function downloadSelectedRows() {
        const data = tableState.rowSelectionModel.map(selectedRowId => dataGridApiRef.current.getRow(selectedRowId));
        // Remove hidden cols from export json
        schema.hiddenColNames.forEach(name => {
            data.forEach(row => delete row[name]);
        });
        
        const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data, undefined, 4))}`;
        const tempLink = document.createElement("a");
        tempLink.href = jsonString;
        tempLink.download = `${table}.json`;
        tempLink.click();
    }

    function canExportRows(): boolean {
        return tableState.rowSelectionModel.length === 0;
    }

    function openAddItemModal() {
        setAddItemModalOpen(true);
    }

    function closeAddItemModal() {
        setAddItemModalOpen(false);
    }

    function addRow() {
        setAddItemModalDefaultValues(undefined);
        setAddItemModalMode(AddModalMode);
        openAddItemModal();
    }

    function editRow(row: any) {
        setAddItemModalDefaultValues(row);
        setAddItemModalMode(EditModalMode);
        openAddItemModal();
    }

    function openDeleteItemModal() {
        setDeleteItemModalOpen(true);
    }

    function closeDeleteItemModal() {
        setDeleteItemModalOpen(false);
    }

    function deleteRow(row: any) {
        setDeleteItemModalRow(row);
        openDeleteItemModal();
    }

    return (
        <div className="DataTable" key={table}>
            <div className="buttonBar">
                <span>
                    <Button className="barButton" variant="contained" startIcon={<Refresh />}
                        onClick={() => refreshTable()}>Ricarica</Button>
                </span>
                { addItemButton &&
                    <span>
                        <Button className="barButton" variant="contained" startIcon={<Add />} disabled={!addItemButton || loading}
                            onClick={() => addRow()}>{addItemButtonTitle ? addItemButtonTitle : "Aggiungi"}</Button>
                    </span>
                }
                <Tooltip title={canExportRows() ? "Seleziona alcune righe" : ""}>
                    <span>
                        <Button className="barButton" variant="contained" startIcon={<Download />}
                            disabled={!exportDataButton || canExportRows()}
                            onClick={downloadSelectedRows}>Esporta</Button>
                    </span>
                </Tooltip>
                { importDataButton &&
                    <Button className="barButton" variant="contained" startIcon={<Upload />} disabled={loading}>Importa</Button>
                }
                { additionalButtons }
                { quickFilterConfig &&
                    <FormControlLabel className="quickFilter" label={quickFilterConfig.label} control={<Checkbox disabled={loading} checked={quickFilterChecked} onChange={handleQuickFilterChange} />} />
                }
            </div>
            <DataGrid apiRef={dataGridApiRef} paginationMode="server" loading={loading} rowCount={rowCount} onPaginationModelChange={handlePageChange}
                rows={rows} columns={getFormattedColumnDefinition()} pageSizeOptions={[10, 25, 50]} checkboxSelection paginationModel={tableState.paginationModel}
                autoHeight onFilterModelChange={handleFilterChange} filterMode="server" filterModel={tableState.filterModel}
                onSortModelChange={handleSortChange} sortingMode="server" sortModel={tableState.sortModel} onRowSelectionModelChange={handleRowSelection}
                columnVisibilityModel={tableState.columnVisibilityModel} disableRowSelectionOnClick />
            { (addItemButton || editAction) &&
                <AddItemModal onSuccessFunction={() => refreshTable()} open={addItemModalOpen} parentOpenFunction={addRow} parentCloseFunction={closeAddItemModal}
                    schema={schema} auth={auth} api={api} table={table} defaultValues={addItemModalDefaultValues} mode={addItemModalMode}
                    userId={userId} apiRef={dataGridApiRef} getEditDisabledFields={getEditDisabledFields} />
            }
            { deleteAction &&
                <DeleteItemModal onSuccessFunction={() => refreshTable()} open={deleteItemModalOpen} parentOpenFunction={openDeleteItemModal}
                    parentCloseFunction={closeDeleteItemModal} auth={auth} api={api} table={table} row={deleteItemModalRow} />
            }
            <TextDisplayModal open={textDisplayModalOpen} parentCloseFunction={() => setTextDisplayModalOpen(false)}
                parentOpenFunction={() => setTextDisplayModalOpen(true)} title={textDisplayModalState.title} text={textDisplayModalState.text} />
        </div>
    );
}
