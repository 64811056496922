import "./Resoconto.scss";
import { Download, People, ReceiptLong } from "@mui/icons-material";
import { Paper, FormControl, InputLabel, Select, MenuItem, Divider, Button, SelectChangeEvent, Card, CardHeader, CardContent, Typography, Backdrop, CircularProgress, Tabs, Tab, Box, Stack, Tooltip } from "@mui/material";
import { useState, ReactNode, useEffect } from "react";
import ApiAdapter from "../../api/ApiAdapter";
import { CertificateAcquiredSchema, CourseAttendedSchema } from "../../utils/schemas";
import { useAuth } from "oidc-react";
import dayjs from "dayjs";
import StringUtils from "../../utils/StringUtils";
import { Link } from "react-router-dom";
import { SelectOption } from "../../utils/types";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function ResocontoContent({
    userId,
    isExternalUserId = false,
    api
}: {
    userId: string,
    isExternalUserId?: boolean,
    api: ApiAdapter
}) {
    const [selectedValue, setSelectedValue] = useState<string>(CertificateAcquiredSchema.name);
    const [loading, setLoading] = useState(true);
    const [itemsToShow, setItemsToShow] = useState<any[]>([]);
    const [tabValue, setTabValue] = useState(0);

    const auth = useAuth();

    const cardFormatters = {
        [CertificateAcquiredSchema.name]: (data: any, index: number): React.ReactNode => {
            return <Card key={`certificates-card-${index}`} variant="elevation" sx={{ width: 1 }}>
                <CardHeader title={data.certificate.name} subheader={data.url} />
                <CardContent>
                    <Typography>Data di Acquisizione: {dayjs(data.acquiry_date).format("DD/MM/YYYY")}</Typography>
                    <Typography>Data di Scadenza: {dayjs(data.expiry_date).format("DD/MM/YYYY")}</Typography>
                    <Typography fontSize={16} color={(theme) => theme.palette.grey[600]} sx={{ marginTop: "8px" }}>{data.certificate.description}</Typography>
                </CardContent>
            </Card>
        },
        [CourseAttendedSchema.name]: (data: any, index: number): React.ReactNode => {
            return <Card key={`courses-card-${index}`} variant="elevation" sx={{ width: 1 }}>
                <CardHeader title={data.course.name} subheader={data.course.url} />
                <CardContent>
                    <Typography>Data di Inizio: {dayjs(data.start_date).format("DD/MM/YYYY")}</Typography>
                    {data.completion_date && <Typography>Data di Completamento: {dayjs(data.completion_date).format("DD/MM/YYYY")}</Typography>}
                    {data.discontinuance_date && <Typography>Data di Abbandono: {dayjs(data.discontinuance_date).format("DD/MM/YYYY")}</Typography>}
                    <Typography>Durata: {data.course.duration} ore</Typography>
                    <Typography fontSize={16} color={(theme) => theme.palette.grey[600]} sx={{ marginTop: "8px" }}>{data.course.description}</Typography>
                    {data.in_presence && <Typography>{StringUtils.getFormattedAddress(data.course.street, data.course.house_number, data.course.city, data.course.postal_code)}</Typography>}
                </CardContent>
            </Card>
        }
            
    }

    useEffect(() => {
        setTabValue(0);
        setLoading(true);
        fetchItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    function handleSelection(event: SelectChangeEvent<string>, child?: ReactNode) {
        setSelectedValue(event.target.value);
    }

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        setTabValue(newValue);
    }

    async function fetchItems() {
        if (!auth.userData) {
            return;
        }

        const items = await api.getAllDataByUserId(userId, selectedValue, auth.userData.access_token);
        setItemsToShow(items);
        setLoading(false);
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
            <div role="tabpanel" className="tabpanel" hidden={value !== index} id={`${selectedValue}-tabpanel-${index}`} {...other}>
                {value === index && <Box sx={{ p: 3, width: 1 }}>{children}</Box>}
            </div>
        );
    }

    function getRenderedFilteredItems(possibility: SelectOption): ReactNode | ReactNode[] {
        const filteredItems = itemsToShow.filter(item => item.state === possibility.value);
        if (filteredItems.length === 0) {
            return <Typography color={(theme) => theme.palette.grey[600]}>Nessun elemento trovato.</Typography>;
        }
        return filteredItems.map((item, itemIndex) => cardFormatters[selectedValue](item, itemIndex));
    }

    return (
        <>
            <Paper className="mainContainer">
                <FormControl className="formControl">
                    <InputLabel id="selector-label">Tipologia</InputLabel>
                    <Select className="selectTabella" label="Tipologia" labelId="selector-label" value={selectedValue} onChange={handleSelection} sx={{ width: "160px" }}>
                        <MenuItem key={"menu-certificates"} value={CertificateAcquiredSchema.name} selected={selectedValue === CertificateAcquiredSchema.name}>Certificazioni</MenuItem>
                        <MenuItem key={"menu-courses"} value={CourseAttendedSchema.name} selected={selectedValue === CourseAttendedSchema.name}>Corsi</MenuItem>
                    </Select>
                </FormControl>
                <Divider orientation="vertical" sx={{ marginLeft: "8px", marginRight: "8px" }} />
                <Tooltip title="Work in Progress">
                    <span>
                        <Button startIcon={<Download />} variant="outlined" disabled>Scarica</Button>
                    </span>
                </Tooltip>
                { isExternalUserId &&
                    <Stack gap={"8px"} direction={"row"}>
                        <Link to={"/team"} state={{}}><Button variant="contained" startIcon={<People />} sx={{ justifyContent: "left" }} fullWidth><Typography>Torna al tuo Team</Typography></Button></Link>
                        <Link to={"/resoconto"} state={{}}><Button variant="contained" startIcon={<ReceiptLong />} sx={{ justifyContent: "left" }} fullWidth><Typography>Vai al tuo Resoconto</Typography></Button></Link>
                    </Stack>
                }
            </Paper>
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" sx={{ width: "100%", paddingInline: "16px" }}>
                { selectedValue === CertificateAcquiredSchema.name &&
                    CertificateAcquiredSchema.additionalInfo!.state!.possibilities!.map((possibility, index) => {
                        return <Tab label={possibility.name} key={`${selectedValue}-tab-${index}`} />
                    })
                }
                { selectedValue === CourseAttendedSchema.name &&
                    CourseAttendedSchema.additionalInfo!.state!.possibilities!.map((possibility, index) => {
                        return <Tab label={possibility.name} key={`${selectedValue}-tab-${index}`} />
                    })
                }
            </Tabs>
            { selectedValue === CertificateAcquiredSchema.name &&
                CertificateAcquiredSchema.additionalInfo!.state!.possibilities!.map((possibility, tabIndex) => {
                    return <CustomTabPanel value={tabValue} index={tabIndex} key={`${selectedValue}-customTab-${tabIndex}`}>
                        <div className="cardContainer">
                            { !loading && getRenderedFilteredItems(possibility) }
                        </div>
                    </CustomTabPanel>
                })
            }
            { selectedValue === CourseAttendedSchema.name &&
                CourseAttendedSchema.additionalInfo!.state!.possibilities!.map((possibility, tabIndex) => {
                    return <CustomTabPanel value={tabValue} index={tabIndex} key={`${selectedValue}-customTab-${tabIndex}`}>
                        <div className="cardContainer">
                            { !loading && getRenderedFilteredItems(possibility) }
                        </div>
                    </CustomTabPanel>
                })
            }
        </>
    );
}