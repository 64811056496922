import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { useState } from "react";
import { SelectOption } from "../../../utils/types";

export type EnumSelectFilterProps = GridFilterInputValueProps & {
    possibilities: SelectOption[]
}

export default function EnumSelectFilter(props: EnumSelectFilterProps) {
    // eslint-disable-next-line
    const { item, applyValue, focusElementRef, possibilities } = props;
    const [ selectedValue, setSelectedValue ] = useState("");

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);

        applyValue({ ...item, value: event.target.value });
    };

    return (
        <FormControl fullWidth>
            <InputLabel key="test-select-label" variant="standard" htmlFor="test-native-select">Value</InputLabel>
            <NativeSelect key={"test-select"} value={selectedValue} inputProps={{ name: "select", "id": "test-native-select"}} onChange={handleFilterChange}>
                <option value={""} key={"blank-option"}></option>
                {
                    possibilities.map(selectOption => <option value={selectOption.value} key={selectOption.value}>{selectOption.name}</option>)
                }
            </NativeSelect>
        </FormControl>
    )
}