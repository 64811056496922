import { useAuth } from "oidc-react";
import DataTable from "../../components/common/DataTable/DataTable";
import PageLabelBar from "../../components/common/PageLabelBar/PageLabelBar";
import ApiProvider from "../../utils/ApiProvider";
import UserDataUtils from "../../utils/UserDataUtils";
import { SkillAcquiredSchema } from "../../utils/schemas";
import { Link, useLocation } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { Lightbulb, People } from "@mui/icons-material";

export default function Skills() {
    const auth = useAuth();
    const location = useLocation();
    
    let externalUserId: string | undefined;
    let externalUserName: string | undefined;
    if (location.state) {
        if (location.state.userId) {
            externalUserId = location.state.userId;
        }

        if (location.state.name) {
            externalUserName = location.state.name;
        }
    }

    return (
        <div className="content">
            <PageLabelBar title={`Skills${externalUserName ? ` di ${externalUserName}` : ""}`} />
            <div className="fullWidth">
                { auth.userData &&
                    <DataTable key={externalUserId} api={ApiProvider.getApi()} table={SkillAcquiredSchema.name} addItemButton={externalUserId ? false : true} exportDataButton
                        userId={externalUserId ?? UserDataUtils.getUserId(auth.userData)} editAction deleteAction
                        editActionPredicate={(row) => row.level > 0} deleteActionPredicate={(row) => row.level > 0}
                        quickFilterConfig={{ field: "level", operator: ">", value: "0", label: "Mostra solo skill acquisite" }}></DataTable>
                }
            </div>
            { externalUserId &&
                <div className="belowDataTableButtonBar fullWidth">
                    <Stack gap={"8px"} width={"fit-content"}>
                        <Link to={"/team"} state={{}}><Button variant="contained" startIcon={<People />} sx={{ justifyContent: "left" }} fullWidth><Typography>Torna al tuo Team</Typography></Button></Link>
                        <Link to={"/skills"} state={{}}><Button variant="contained" startIcon={<Lightbulb />} sx={{ justifyContent: "left" }} fullWidth><Typography>Vai alle tue Skills</Typography></Button></Link>
                    </Stack>
                </div>
            }
        </div>
    );
}